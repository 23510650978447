<template>
  <v-sheet height="100%" width="100%">
    <template>
      <v-app-bar flat color="transparent" width="100%" class="fixed-bar-b">
        <v-icon
          large
          v-if="this.store.menus"
          @click="edit_stores = !edit_stores"
          >mdi-store-edit-outline
        </v-icon>
        <v-spacer></v-spacer>
        <v-btn color="warning" fab x-small dark @click="newForm()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container>
        <v-data-table
          :headers="headers[$vuetify.breakpoint.smAndDown]"
          :items="allProducts"
          :items-per-page="10"
          item-key="code"
          sort-by="category"
          multi-sort
          :search="search"
          mobile-breakpoint="0"
          class="table-cursor mt-3"
          :loading="loading_status"
          loader-height="10"
          loading-text="Cargando ..."
          @click:row="editProduct"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Buscar"
              class="mx-4"
              clearable
            />
          </template>
          <template
            v-slot:[`item.picture`]="{ item }"
            v-if="item.picture !== ''"
          >
            <v-list-item-avatar>
              <v-img
                :src="getImagePhoto(item.account, item.store, item.picture)"
              ></v-img>
            </v-list-item-avatar>
          </template>
          <template v-slot:[`item.product_name`]="{ item }">
            {{ item.product_name  }} <br />
            <small>{{ " X " + item.size + " " + item.uom}}</small>
            <template v-if="$vuetify.breakpoint.smAndDown">
            <small>
              {{ item.category + " " + item.brand }}
            </small>
            <span v-html="item.description"></span>
            </template>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <span v-html="item.description"></span>
          </template>

          <template v-slot:[`item.price`]="{ item }">
            {{ "$" + parseFloat(item.price).toLocaleString(2) }}
          </template>
        </v-data-table>
      </v-container>
    </template>

    <div v-if="categoryName">
      <v-row fill-height justify="center" v-if="$vuetify.breakpoint.smAndDown">
        <v-col
          v-for="(child, index) in category_selected"
          :key="index"
          cols="12"
          md="6"
        >
          <v-list-item three-line @click="editProduct(child)">
            <v-list-item-avatar size="80" rounded v-if="child.picture">
              <v-img
                cover
                :src="getImagePhoto(child.account, child.store, child.picture)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="child.product_name"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="child.description"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-text="'$' + parseFloat(child.price).toLocaleString()"
            >
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>

      <v-row fill-height justify="center" v-if="!$vuetify.breakpoint.smAndDown">
        <v-col
          v-for="(child, index) in category_selected"
          :key="index"
          cols="12"
          md="6"
        >
          <v-list-item @click="editProduct(child)">
            <v-list-item-avatar
              horizontal
              size="120"
              rounded
              v-if="child.picture"
            >
              <v-img
                cover
                :src="getImagePhoto(child.account, child.code, child.picture)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="child.product_name"
              ></v-list-item-title>

              <v-list-item-subtitle
                v-html="child.description"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              small
              v-text="'$' + parseFloat(child.price).toLocaleString()"
            >
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </div>

    <MenuForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      :categories="categories"
      @close="dialog = !dialog"
      :subcategories="subcategories"
      :brands="brands"
      :suppliers="suppliers"
      @refresh="refresh()"
    />
    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
    </v-dialog>
  </v-sheet>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";
import createParty from "../../utils/createStore.js";
import MenuForm from "../../components/MenuForm.vue";

import createWebset from "../../utils/createSetting.js";

export default {
  components: { MenuForm },
  data() {
    return {
      settings: createWebset(),
      edit_stores: false,
      newStore: false,
      groups: [],
      dialog_stores: true,
      stores: [],
      search_store: "",
      fontsLst: [
        "Amatic",
        "Architects",
        "Carattere",
        "Montserrat",
        "Roboto",
        "Shadows",
      ],
      theme: "Montserrat",
      menutype: false,
      whitPhoto: false,
      setting: false,
      table: "catalogue",
      search: "",
      errors: [],
      store: createParty(),
      head_bg_pic: "https://picsum.photos/1920/1080?random",
      valid: false,
      dialog: false,
      newItem: false,
      loading_status: false,
      category_selected: [],
      categoryName: null,
      byCategories: [],
      categories: [],
      subcategories: [],
      brands: [],
      suppliers: [],
      headers: {
        true: [
          {
            text: "",
            align: "start",
            value: "picture",
          },

          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
        ],
        false: [
          {
            text: "",
            align: "start",
            value: "picture",
          },
          {
            text: "Categoría",
            align: "start",
            sortable: true,
            value: "category",
            dataType: "text",
          },

          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
           {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "description",
            dataType: "text",
          },
          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
          },
        ],
      },
      items: [],
      item: createItem(),
      model: "tab-0",
      tabs: [
        {
          name: "1st Tab",
          text: "This is a 1st tab",
        },
        {
          name: "2nd Tab",
          text: "This is a 2nd tab",
        },
        {
          name: "3rd Tab",
          text: "This is a 3rd tab",
        },
        {
          name: "4th Tab",
          text: "This is a 4th tab",
        },
      ],
      allProducts: [],
      headers_store: {
        false: [
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "group_name",
            dataType: "text",
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Dirección",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "Tel.",
            align: "end",
            value: "mobile",
            dataType: "text",
            width: 150,
          },
        ],
        true: [
          {
            text: "",
            align: "start",
            sortable: true,
            value: "picture",
            dataType: "text",
            width: 40,
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
        ],
      },
    };
  },
  mounted() {
    if (window.store) {
      this.store = window.store;
      this.item.account = this.store.account;
      this.item.store = this.store.code;
      if (this.store.setting) {
        this.settings = JSON.parse(this.store.setting);
      }
    }
    this.store = this.$store.getters.company;
    this.item.account = this.store.account;
    this.item.store = this.store.code;

    this.get_items();
  },
  methods: {
    editProduct(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    get_items() {
      this.items = [];
      this.byCategories = [];
      this.loading_status = true;
      var qry = {
        account: this.store.account,
        table: this.table,
        filters: [{ field: "store", value: this.store.code, operator: "=" }],
      };
      console.log(qry);
      webserver("get_table", qry, (data) => {
        data.forEach((rg) => {
          if (rg.discount) rg.discount = parseFloat(rg.discount);
          if (rg.brand) rg.brand = rg.brand.toUpperCase();
          if (rg.supplier) rg.supplier = rg.supplier.toUpperCase();
          if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
        });
        console.log(data);
        this.allProducts = data;

        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));
        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        this.byCategories = ctgry;
        this.categories = Object.keys(ctgry).sort();
        this.category_selected = ctgry;

        // if (this.categoryName) {
        //   this.category_selected = ctgry[this.categoryName];
        // } else {
        //   this.category_selected = ctgry[Object.keys(ctgry)[0]];
        // }

        this.head_bg_pic = this.getImagePhoto(
          this.store.account,
          this.store.code,
          this.store.picture
        );

        var sbctgry = data.reduce(function (r, a) {
          r[a.subcategory] = r[a.subcategory] || [];
          r[a.subcategory].push(a);
          return r;
        }, Object.create(null));
        this.subcategories = Object.keys(sbctgry).sort();

        var brnd = data.reduce(function (r, a) {
          r[a.brand] = r[a.brand] || [];
          r[a.brand].push(a);
          return r;
        }, Object.create(null));
        this.brands = Object.keys(brnd).sort();

        var spplr = data.reduce(function (r, a) {
          r[a.supplier] = r[a.supplier] || [];
          r[a.supplier].push(a);
          return r;
        }, Object.create(null));
        this.suppliers = Object.keys(spplr).sort();

        this.loading_status = false;
        this.items = data;
        //if (data.length === 0) this.newForm();
      });
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.store.account;
      this.item.store = this.store.code;
      this.valid = false;
      this.newItem = true;
      this.dialog = !this.dialog;
    },
    changeMenu(e) {
      // this.category_selected = this.byCategories[e];
      // this.categoryName = e;
      this.head_bg_pic = this.getbgpic(e);
      this.$vuetify.goTo("#tab-" + e.replace(/\s/g, ""));
    },
    refresh() {
      console.log("refresh");
      this.get_items();
      this.dialog = false;
      this.categoryName = null;
    },
    updateStore(e) {
      console.log(e);
    },
    getImagePhoto: function (account, store, src) {
      var url =
        "https://smartchef.pro/photos/" + account + "/" + store + "/" + src;
      return url;
    },
    getImageMenu: function (account, code, src) {
      var url =
        "https://smartchef.pro/menus/" + account + "/" + code + "/" + src;
      return url;
    },
    bgjpg() {
      return "https://picsum.photos/1920/1080?random";
    },
    getbgpic(ctgry) {
      var phtslst;
      if (ctgry) {
        phtslst = this.byCategories[ctgry];
      } else {
        phtslst = this.allProducts;
      }

      phtslst = phtslst.filter(function (el) {
        return el.picture !== "";
      });

      if (phtslst.length > 0) {
        var rdm = phtslst[(phtslst.length * Math.random()) | 0];

        if (rdm.picture) {
          return this.getImagePhoto(rdm.account, rdm.store, rdm.picture);
        } else {
          if (window.store.picture) {
            return this.getImagePhoto(
              window.store.account,
              window.store.store,
              window.store.picture
            );
          }
          return "https://picsum.photos/1920/1080?random";
        }
      } else {
        return "https://picsum.photos/1920/1080?random";
      }
    },
    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
  },
  watch: {
    item: function (e) {
      this.storePhoto = this.getImagePhoto(e.account, e.store, e.picture);
      console.log(e);
    },
  },
};
</script>

<style>
.Montserrat {
  font-family: "Montserrat", sans-serif !important;
}
.Roboto {
  font-family: "Roboto Condensed", sans-serif !important;
}

.Architects {
  font-family: "Architects Daughter", cursive !important;
}

.Shadows {
  font-family: "Shadows Into Light", cursive !important;
}

.Amatic {
  font-family: "Amatic SC", cursive !important;
}

.Carattere {
  font-family: "Carattere", cursive !important;
}

.fixed-bar-b {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 56px;
  z-index: 2;
}

.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 70px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 2;
}

.tshadow {
  position: absolute;
  overflow: auto !important;
  text-shadow: black -1px 2px 6px;
  padding: 10px;
  color: white;
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  bottom: 5px;
}

.file-input {
  display: none;
}
.centered-input input {
  text-align: center;
}
.table-cursor tbody tr:hover {
  cursor: pointer;
}
.text-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>

